import {
  Banner,
  Button,
  CheckBox,
  Modal,
  Snackbar,
  SWAPSpace,
  Tab,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from "@yosgo/swap-ui";
import React, { useEffect, useState, useCallback } from "react";
import { validateTaxId } from "../../utils/ValidateTaxId";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Box,
  Fade,
  IconButton,
  useMediaQuery,
  makeStyles,
  useTheme,
  Theme,
  InputAdornment,
  debounce,
} from "@material-ui/core";
import {
  Login,
  RegisterMember,
  RequestResetPassword,
  RegisterBusiness,
} from "../../utils/AppRequest";
import { ParseAccountToken, WriteAccountToken } from "../../utils/HandleToken";
import PathAndTitle from "../../utils/PathAndTitle";
import styled from "styled-components";
import { WindowExist } from "../../utils/HandleMode";
import NormalLink from "./NormalLink";
import { PathOr } from "../../utils/FunctionsBox";
import QS from "../../utils/QS";
import axios from "axios";
import { FreshChatSaveRestoreIdFromBiz } from "./FreshChat";
import jwt from "jsonwebtoken";

const handleEmailValidation = (email: string) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Link = styled.a<any>`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: black;
`;
// Style
interface StyleProps {
  isIOSMessage?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  textfield_password: {
    "& input": {
      "&::-ms-reveal ": {
        display: "none",
      },
    },
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.black.black600,
      borderRadius: 100,
    },
    "&::-webkit-scrollbar-track-piece:start": {
      marginTop: 12,
    },
    "&::-webkit-scrollbar-track-piece:end": {
      marginBottom: 12,
    },
  },
  googleButton: (props) => ({
    opacity: props.isIOSMessage ? 0.4 : 1,
    textAlign: "center",
    width: "100%",
    height: 48,
    backgroundColor: "white",
    cursor: props.isIOSMessage ? "not-allowed" : "pointer",
    padding: "14px 24px",
    position: "relative",
    border: "1px solid #909090",
    boxSizing: "border-box",
    borderRadius: 8,
    boxShadow: "none",
    color: "black",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "18px",
    fontFamily: "unset",
    "&:hover": {
      backgroundColor: props.isIOSMessage ? "transparent" : "#F6F6F6",
    },
  }),
  facebookButton: {
    textAlign: "center",
    width: "100%",
    height: 48,
    marginBottom: 12,
    backgroundColor: "white",
    cursor: "pointer",
    padding: "14px 24px",
    position: "relative",
    border: "1px solid #909090",
    boxSizing: "border-box",
    borderRadius: 8,
    fontSize: 16,
    lineHeight: "18px",
    fontWeight: 700,
    fontFamily: "unset",
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
}));

interface AuthModalProps {
  open: boolean;
  onClose: () => void;
  type: string;
}

const AuthModal = ({ open, onClose, type }: AuthModalProps) => {
  const [value, setValue] = useState(0 || 1);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [tokenEmail, setTokenEmail] = useState("");
  const [clienId, setClienId] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const [thirdPartyLoginError, setThirdPartyLoginError] = useState(false);
  const [thirdPartyResgisterError, setThirdPartyResgisterError] =
    useState(false);
  const [thirdPartyAPIError, setThirdPartyAPIError] = useState(false);
  const [thirdPartyAPIErrorCode, setThirdPartyAPIErrorCode] = useState("");
  const [isIOSMessage, setIsIOSMessage] = useState(false);
  const [matchSM, setMatchSM] = useState(false);
  useEffect(() => {
    setValue(type === "login" ? 0 : 1);
  }, [type]);
  useEffect(() => {
    // 若已登入，自動填入帳號(Email)
    setTokenEmail(PathOr("", ["email"], ParseAccountToken("biz")));

    // 判斷環境 (staging ||　production)
    const isStaging =
      window.location.origin.indexOf("staging.swap.work") >= 0 ||
      window.location.origin.indexOf("localhost") >= 0;

    // google oauth clienID (develop or production)
    setClienId(
      !isStaging
        ? "684082989061-q2rjgagfqkdvcihjkvgebd9r1u03nq4a.apps.googleusercontent.com"
        : "684082989061-d4ru5m8npkb4hg95i3r2lliro609hkj6.apps.googleusercontent.com"
    );

    // redirect-uri(develop or production)
    setRedirectUri(
      !isStaging
        ? "https://swap.work/loadingpage"
        : "https://staging.swap.work/loadingpage"
    );

    // 判斷第三方登入/註冊 錯誤狀態
    const state: any = QS("state", window.location.href);
    if (state === "login") {
      setThirdPartyLoginError(true);
    }
    if (state === "register") {
      setThirdPartyResgisterError(true);
    }
    // 判斷錯誤訊息是否是來自 第三方API
    const error: any = QS("error", window.location.href);
    const status: any = QS("status", window.location.href);

    if (
      error !== "Unauthorization" &&
      error !== "member not found" &&
      error !== "重複註冊"
    ) {
      setThirdPartyAPIError(true);
      setThirdPartyAPIErrorCode(status);
    }

    // 判斷是否為 IOS Message in-app 瀏覽器
    const userAgent = navigator.userAgent;
    const isFB = userAgent.indexOf("FBAV") > -1;
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    if (isFB && isIOS) {
      setIsIOSMessage(true);
    } else {
      setIsIOSMessage(false);
    }
  }, []);

  // 滑動關閉Snackbar Event
  WindowExist()
    ? (window.onscroll = function handleScroll() {
        if (isOpenSnackbar) {
          setIsOpenSnackbar(false);
        }
      })
    : null;

  const match_SM = useMediaQuery("(min-width:576px)");

  useEffect(() => {
    setMatchSM(match_SM);
  }, [match_SM]);

  return (
    <>
      <Modal
        bodyStyle={{ overflowY: "unset" }}
        bodyPadding={matchSM ? "0 0" : "0 0"}
        headpadding={resetPassword ? undefined : "0px 24px"}
        open={open}
        maxWidth={480}
        mobile={!matchSM}
        onClose={() => {
          onClose();
          setResetPassword(false);
        }}
        title={
          !resetPassword ? (
            <Tabs value={value}>
              <Tab
                animation
                label="登入帳號"
                width={72}
                height={64}
                margin="0px 12px 0px 0px"
                fontSize={18}
                onClick={() => {
                  setValue(0);
                  document
                    .getElementById("top")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              />
              <Tab
                animation
                label="註冊帳號"
                width={72}
                height={64}
                margin={0}
                fontSize={18}
                onClick={() => {
                  setValue(1);
                  document
                    .getElementById("top")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </Tabs>
          ) : (
            "找回你的密碼"
          )
        }
      >
        <div id="top" style={{ position: "absolute", top: 0 }} />
        {resetPassword ? (
          <ResetPassword
            onClose={onClose}
            setResetPassword={setResetPassword}
            setIsOpenSnackbar={setIsOpenSnackbar}
            email={email}
            setEmail={setEmail}
          />
        ) : (
          <>
            {/* 登入 */}
            <TabPanel value={value} index={0}>
              <LoginModal
                setResetPassword={setResetPassword}
                tokenEmail={tokenEmail}
                clienId={clienId}
                redirectUri={redirectUri}
                thirdPartyLoginError={thirdPartyLoginError}
                thirdPartyAPIError={thirdPartyAPIError}
                thirdPartyAPIErrorCode={thirdPartyAPIErrorCode}
                isIOSMessage={isIOSMessage}
                matchSM={matchSM}
              />
            </TabPanel>
            {/* 註冊 */}
            <TabPanel value={value} index={1}>
              <RegisterModal
                setValue={setValue}
                clienId={clienId}
                redirectUri={redirectUri}
                thirdPartyResgisterError={thirdPartyResgisterError}
                thirdPartyAPIError={thirdPartyAPIError}
                thirdPartyAPIErrorCode={thirdPartyAPIErrorCode}
                isIOSMessage={isIOSMessage}
                matchSM={matchSM}
              />
            </TabPanel>
          </>
        )}
      </Modal>
      <Snackbar
        width="100%"
        open={isOpenSnackbar}
        onClose={() => setIsOpenSnackbar(false)}
        autoHideDuration={5000}
        transitionDirection="up"
        transitionDuration={{ enter: 500, exit: 500 }}
        message={`重置密碼的信件已寄至 ${email}`}
        action={
          <IconButton
            style={{ padding: 0, marginRight: 12 }}
            onClick={() => setIsOpenSnackbar(false)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="white"
              />
            </svg>
          </IconButton>
        }
      />
    </>
  );
};

interface LoginModalProps {
  setResetPassword: (resetPassword: boolean) => void;
  tokenEmail: string;
  clienId: string;
  redirectUri: string;
  thirdPartyLoginError: boolean;
  thirdPartyAPIError: boolean;
  thirdPartyAPIErrorCode: string;
  isIOSMessage: boolean;
  matchSM: boolean;
}

const LoginModal = ({
  setResetPassword,
  tokenEmail,
  matchSM,
}: LoginModalProps) => {
  const theme = useTheme();
  const [email, setEmail] = useState(tokenEmail);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [errorBorderFieldFirst, setErrorBorderFieldFirst] = useState(false);
  const [errorBorderFieldSecond, setErrorBorderFieldSecond] = useState(false);
  const [errorTextField, setErrorTextField] = useState(0);
  const [oauthOrigin, setOauthOrigin] = useState("");
  const [showPasswordResetSuccess, setShowPasswordResetSuccess] =
    useState(false);

  useEffect(() => {
    const r = QS("reset", window.location.href);
    const t = QS("type", window.location.href);
    if (r === "success" && t === "business") {
      setShowPasswordResetSuccess(true);
    }
  }, []);

  const handleThirdPartyAuthLogin = async () => {
    setLoading(true);
    const loginResult: any = await Login({
      email: email,
      password: password,
      type: "business",
      oauth_origin: oauthOrigin,
    });
    setLoading(false);
    if (loginResult) {
      if (loginResult.errors === null) {
        (async () => {
          /**寫入 token */
          WriteAccountToken(loginResult.payload.token);
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(loginResult.payload.token);
          const id = PathOr(undefined, ["id"], token);
          const restore_id = PathOr(undefined, ["fc_restore_id"], token);
          if (!restore_id) {
            await FreshChatSaveRestoreIdFromBiz(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = PathAndTitle.business.overview.path)
            );
          } else {
            /**導向至帳戶首頁*/
            window.location.href = PathAndTitle.business.overview.path;
          }
        })();
      } else {
        setOauthOrigin("");
        setErrorMessage("");
        setErrorMessages("");
        setErrorBorderFieldFirst(false);
        setErrorBorderFieldSecond(false);
      }
    }
  };

  useEffect(() => {
    if (oauthOrigin !== "") {
      handleThirdPartyAuthLogin();
    }
  }, [oauthOrigin, email, password]);

  const handleLogin = async () => {
    try {
      /**驗證 */
      if (email.length === 0) {
        throw "請輸入帳號";
      }
      if (!handleEmailValidation(email)) {
        throw "請確認信箱與格式是否正確";
      }
      if (password.length < 8) {
        throw "密碼格式為 8 個字元以上";
      }
      /**登入 */
      setLoading(true);
      const loginResult: any = await Login({
        email: email,
        password: password,
        type: "business",
        oauth_origin: oauthOrigin,
      });

      if (loginResult && loginResult.errors === null) {
        (async () => {
          /**寫入 token */
          WriteAccountToken(loginResult.payload.token);
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(loginResult.payload.token);
          const id = PathOr(undefined, ["id"], token);
          const restore_id = PathOr(undefined, ["fc_restore_id"], token);
          if (!restore_id) {
            await FreshChatSaveRestoreIdFromBiz(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = PathAndTitle.business.overview.path)
            );
          } else {
            /**導向至帳戶首頁*/
            window.location.href = PathAndTitle.business.overview.path;
          }
        })();
      } else {
        throw "您輸入的帳號或是密碼不正確，請再次確認";
      }
    } catch (err: any) {
      setErrorMessage(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (errorMessage.length !== 0) {
      if (
        errorMessage === "請輸入帳號" ||
        errorMessage === "請確認信箱與格式是否正確"
      ) {
        setErrorTextField(1);
        setErrorBorderFieldFirst(true);
        setErrorBorderFieldSecond(false);
      } else if (errorMessage === "密碼格式為 8 個字元以上") {
        setErrorTextField(2);
        setErrorBorderFieldSecond(true);
        setErrorBorderFieldFirst(false);
      } else if (errorMessage === "您輸入的帳號或是密碼不正確，請再次確認") {
        setErrorTextField(2);
        setErrorBorderFieldFirst(true);
        setErrorBorderFieldSecond(true);
      }
      setErrorMessages(errorMessage);
    }
  }, [errorMessage]);
  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  const styleProps: StyleProps = {};
  const classes = useStyles(styleProps);
  return (
    <>
      <div
        className={classes.scrollbar}
        style={{
          overflowY: "scroll",
          padding: matchSM ? "24px 24px 0 24px " : "24px 16px 0 16px",
        }}
      >
        {showPasswordResetSuccess ? (
          <>
            <Banner variant="success">
              密碼已經變更成功，請重新進行登入。
            </Banner>
            <SWAPSpace size={24} />
          </>
        ) : null}
        <TextField
          error={errorBorderFieldFirst}
          helperText={
            errorTextField === 1 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type="email"
          label={email ? "請輸入帳號(Email)" : ""}
          placeholder={!email ? "請輸入帳號(Email)" : ""}
          defaultValue={tokenEmail}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e);
          }}
          InputProps={{
            startAdornment: (
              <MailOutlineIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
          }}
          style={{ marginBottom: 24 }}
        />
        <TextField
          className={classes.textfield_password}
          error={errorBorderFieldSecond}
          helperText={
            errorTextField === 2 ? (
              <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                <Box marginTop="4px">{errorMessages}</Box>
              </Fade>
            ) : null
          }
          fullWidth
          height={56}
          type={visible ? "text" : "password"}
          label={
            password ? (
              <div style={{ height: 14, overflow: "hidden" }}>
                密碼(8個字元以上)
              </div>
            ) : (
              ""
            )
          }
          placeholder={!password ? "密碼(8個字元以上)" : ""}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e);
          }}
          InputProps={{
            startAdornment: (
              <LockOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
            endAdornment: (
              <IconButton
                onClick={() => setVisible(!visible)}
                disableRipple
                style={{ width: 24, height: 24 }}
              >
                {visible ? (
                  <Visibility
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      width: 24,
                      height: 24,
                      color: theme.black.black700,
                    }}
                  />
                )}
              </IconButton>
            ),
          }}
          style={{
            marginBottom: 16,
          }}
        />
      </div>

      <div
        style={{
          padding: matchSM ? "0 24px 24px 24px " : "0 16px 16px 16px",
        }}
      >
        <Button
          variant="primary"
          loading={loading}
          fullWidth
          onClick={handleLogin}
          style={{ marginBottom: 16 }}
        >
          馬上登入
        </Button>
        <Typography
          variant={matchSM ? "body1" : "caption2"}
          style={{ textAlign: "center" }}
        >
          忘記密碼？
          <NormalLink
            onClick={() => setResetPassword(true)}
            style={{ fontWeight: 700 }}
          >
            點這裡找回密碼
          </NormalLink>
        </Typography>
      </div>
    </>
  );
};

interface RegisterModalProps {
  setValue: (value: number) => void;
  clienId: string;
  redirectUri: string;
  thirdPartyResgisterError: boolean;
  thirdPartyAPIError: boolean;
  thirdPartyAPIErrorCode: string;
  isIOSMessage: boolean;
  matchSM: boolean;
}

const RegisterModal = ({ setValue, matchSM }: RegisterModalProps) => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [errorTextField, setErrorTextField] = useState(0);
  const [loading, setLoading] = useState(false);
  const [oauthOrigin, setOauthOrigin] = useState("");
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [companyTaxIdNotValid, setCompanyTaxIdNotValid] = useState(false);
  const [companyTaxIdHelperText, setCompanyTaxIdHelperText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyTaxId, setCompanyTaxId] = useState("");
  const [officialCompanyName, setOfficialCompanyName] = useState("");

  const [disableCompanyName, setDisableCompanyName] = useState(true);
  const [companyNameIsNotValid, setCompanyNameIsNotValid] = useState(false);
  const [companyNameHelperText, setCompanyNameHelperText] = useState("");
  const [taxIdFocused, setTaxIdFocused] = useState(false);
  const [companyNameFocused, setCompanyNameFocused] = useState(false);
  const [, setCompanyNameLabelFontWeight] = useState<
    | "normal"
    | "inherit"
    | "initial"
    | "-moz-initial"
    | "revert"
    | "unset"
    | "bold"
    | (number & {})
    | "bolder"
    | "lighter"
    | undefined
  >("normal");
  const [isNoCompanyWarning, setIsNoCompanyWarning] = useState(false);
  const handleThirdPartyAuthRegister = async () => {
    setLoading(true);
    const registerBusinessResult: any = await RegisterMember({
      email: email,
      password: password,
      oauth_origin: oauthOrigin,
    });
    setLoading(false);
    if (registerBusinessResult) {
      if (registerBusinessResult.errors === null) {
        (async () => {
          /**寫入 token */
          WriteAccountToken(registerBusinessResult.payload.token);
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(registerBusinessResult.payload.token);
          const id = PathOr(undefined, ["id"], token);
          const restore_id = PathOr(undefined, ["fc_restore_id"], token);
          if (!restore_id) {
            await FreshChatSaveRestoreIdFromBiz(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = `${PathAndTitle.business.overview.path}?from=registration`)
            );
          } else {
            /**導向至帳戶首頁*/
            window.location.href = `${PathAndTitle.business.overview.path}?from=registration`;
          }
        })();
      } else {
        setOauthOrigin("");
        setErrorMessage("");
        setErrorMessages("");
        setErrorTextField(0);
      }
    }
  };

  //   檢查公司名稱是否為正式公司名稱（如果統編有查到）
  function validateCompanyName(name: string, officialCompanyName: string) {
    if (!officialCompanyName && name) {
      setCompanyNameIsNotValid(false);
      setCompanyNameHelperText("");
    } else {
      if (name !== officialCompanyName) {
        setCompanyNameIsNotValid(true);
        setCompanyNameHelperText("查無此公司，請確認是否輸入完整公司名稱");
      }

      if (name === officialCompanyName) {
        setCompanyNameHelperText("");
        setCompanyNameIsNotValid(false);
      }
    }
  }
  const handleGetCompanyNameAndCompanyTaxId = (taxId: any) => {
    axios
      .post(
        "https://ko52mudktd.execute-api.ap-northeast-1.amazonaws.com/default/swap-search-taxid",
        JSON.stringify({
          taxid: String(taxId),
        })
      )
      .then((r: any) => {
        if (r.data === "查無此公司") {
          setCompanyName("");
          setOfficialCompanyName("");
          setDisableCompanyName(false);
          setCompanyNameHelperText("請再次確認統一編號，或直接輸入公司名稱");
          setCompanyNameIsNotValid(true);
          setIsNoCompanyWarning(true);
        } else {
          setDisableCompanyName(true);
          setCompanyName(r.data);
          setOfficialCompanyName(r.data);
          setCompanyNameIsNotValid(false);
          setIsNoCompanyWarning(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setCompanyName("");
        setOfficialCompanyName("");
        setDisableCompanyName(false);
        setCompanyNameHelperText("");
        setIsNoCompanyWarning(false);
      });
  };
  useEffect(() => {
    if (oauthOrigin !== "") {
      handleThirdPartyAuthRegister();
    }
  }, [oauthOrigin, email, password]);

  const handleRegister = async () => {
    try {
      /**驗證 */
      if (email.length === 0) {
        throw "請輸入帳號";
      }
      if (!handleEmailValidation(email)) {
        throw "請確認信箱與格式是否正確";
      }
      if (password.length < 8) {
        throw "密碼格式為 8 個字元以上";
      }
      if (password !== passwordConfirm) {
        throw "請確認密碼欄位中的密碼是否一致";
      }
      if (!agreement) {
        throw "請先同意我們的服務條款";
      }
      // 企業會員註冊
      setLoading(true);
      const registerBusinessResult: any = await RegisterBusiness({
        email,
        password,
        company_name: companyName,
        tax_id: companyTaxId,
      });

      if (registerBusinessResult && registerBusinessResult.errors === null) {
        (async () => {
          /**寫入 token */
          WriteAccountToken(registerBusinessResult.payload.token);
          // 設定 Freshchat External Id => 為了產生 Restore id
          const token = jwt.decode(registerBusinessResult.payload.token);
          const id = PathOr(undefined, ["id"], token);
          const restore_id = PathOr(undefined, ["fc_restore_id"], token);
          if (!restore_id) {
            await FreshChatSaveRestoreIdFromBiz(id).then(
              () =>
                /**導向至帳戶首頁*/
                (window.location.href = `${PathAndTitle.business.overview.path}?from=registration`)
            );
          } else {
            /**導向至帳戶首頁*/
            window.location.href = `${PathAndTitle.business.overview.path}?from=registration`;
          }
        })();
      } else {
        throw `此信箱已註冊過(${email})`;
      }
    } catch (err: any) {
      setErrorMessage(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (errorMessage.length !== 0) {
      if (
        errorMessage === "請輸入帳號" ||
        errorMessage === "請確認信箱與格式是否正確" ||
        errorMessage === `此信箱已註冊過(${email})`
      ) {
        setErrorTextField(1);
      } else if (errorMessage === "密碼格式為 8 個字元以上") {
        setErrorTextField(2);
      } else if (errorMessage === "請確認密碼欄位中的密碼是否一致") {
        setErrorTextField(3);
      } else if (errorMessage === "請先同意我們的服務條款") {
        setErrorTextField(4);
      }
      setErrorMessages(errorMessage);
    }
  }, [errorMessage]);

  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleRegister();
    }
  };

  useEffect(() => {
    if (companyTaxIdHelperText !== "") {
      setCompanyTaxIdNotValid(true);
    } else {
      setCompanyTaxIdNotValid(false);
    }
  }, [companyTaxIdHelperText]);

  const alertTaxIdUnderEightDigits = useCallback(
    debounce((value: any) => {
      if (value.length < 8 && value.length > 0) {
        setCompanyTaxIdNotValid(true);
        setCompanyTaxIdHelperText("請確認統一編號已輸入 8 碼");
      }
    }, 1000),
    []
  );

  const styleProps: StyleProps = {};
  const classes = useStyles(styleProps);
  return (
    <Box>
      {/* Step 1 = 輸入統編以及公司名稱 */}
      {step === 0 ? (
        <div
          style={{
            padding: matchSM ? "24px 24px 0 24px " : "24px 16px 0 16px",
          }}
        >
          {isNoCompanyWarning ? (
            <Banner variant="warning" style={{ marginBottom: 24 }}>
              查無公司，請確認統一編號是否正確或直接輸入公司名稱
            </Banner>
          ) : null}
          <TextField
            type="tel"
            height={56}
            autoFocus
            onFocus={() => {
              setTaxIdFocused(true);
            }}
            onBlur={() => {
              setTaxIdFocused(false);
            }}
            onChange={(e) => {
              // 限制統編長度在8位數
              if (e.target.value.length > 8) return;
              // 限制統編為全數字
              if (isNaN(Number(e.target.value))) return;
              setCompanyTaxId(e.target.value);
              // 統編長度等於8
              if (e.target.value.length === 8) {
                validateTaxId(
                  e.target.value,
                  setCompanyName,
                  handleGetCompanyNameAndCompanyTaxId,
                  setCompanyTaxIdHelperText,
                  setCompanyNameLabelFontWeight,
                  "請確認統一編號已輸入 8 碼",
                  "請確認統一編號是否輸入正確",
                  ""
                );
              }
              // 統編長度介於0~8之間提示
              alertTaxIdUnderEightDigits(e.target.value);
              //   統編長度=0
              if (e.target.value.length === 0) {
                setCompanyTaxIdHelperText("");
              }

              // 統編長度小於8，清除公司名稱textField的value & disable
              if (e.target.value.length !== 8) {
                setDisableCompanyName(true);
                setOfficialCompanyName("");
                setCompanyName("");
                setIsNoCompanyWarning(false);
              }
            }}
            InputLabelProps={{
              style: { fontWeight: "bold" },
            }}
            InputProps={{
              style: {},
              startAdornment: (
                <InputAdornment position="start" style={{ marginRight: 10 }}>
                  <TaxIdIcon focused={taxIdFocused} />
                </InputAdornment>
              ),
            }}
            error={companyTaxIdNotValid}
            value={companyTaxId}
            label={companyTaxId ? "公司統一編號(8碼)" : ""}
            placeholder="公司統一編號(8碼)"
            helperText={companyTaxIdHelperText}
            fullWidth
            style={{ marginBottom: 24 }}
          />

          <TextField
            height={56}
            onFocus={() => {
              setCompanyNameFocused(true);
            }}
            onBlur={() => {
              setCompanyNameFocused(false);
            }}
            onChange={(e) => {
              setCompanyName(e.target.value);
              // 如果統編API有查到公司完整名稱，會驗證是否與完整名稱一致
              validateCompanyName(e.target.value, officialCompanyName);
            }}
            InputLabelProps={{
              style: {
                fontWeight: "bold",
                color: "#4B4B4B",
              },
            }}
            InputProps={{
              style: {
                color: "#000000",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <CompanyIcon focused={companyNameFocused} />
                </InputAdornment>
              ),
            }}
            disabled={disableCompanyName}
            value={companyName}
            label={companyName ? "公司名稱(系統自動代入)" : ""}
            placeholder="公司名稱(系統自動代入)"
            helperText={
              <Typography
                color={companyNameIsNotValid ? "danger800" : "black700"}
                variant="body2"
              >
                {companyNameHelperText}
              </Typography>
            }
            error={companyNameIsNotValid}
            fullWidth
            style={{ marginBottom: 16 }}
            onClick={() => {
              if (disableCompanyName === true) {
                setCompanyNameHelperText("系統依照統編自動代入，因此無法修改");
              }
            }}
          />

          <Button
            fullWidth
            variant="primary"
            style={{ marginBottom: 16 }}
            onClick={() => {
              setStep(step + 1);
            }}
            disabled={
              companyName &&
              companyTaxId &&
              !companyTaxIdNotValid &&
              !companyNameIsNotValid
                ? false
                : true
            }
          >
            下一步
          </Button>
        </div>
      ) : null}

      {/* Step 2 = 輸入email以及密碼 */}
      {step === 1 ? (
        <div>
          <div
            className={classes.scrollbar}
            style={{
              overflowY: "scroll",
              padding: matchSM ? "24px 24px 0 24px " : "16px 16px 0 16px",
            }}
          >
            <TextField
              error={errorTextField === 1 ? true : false}
              helperText={
                errorTextField === 1 ? (
                  <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                    <Box marginTop="4px">{errorMessages}</Box>
                  </Fade>
                ) : null
              }
              fullWidth
              height={56}
              type="email"
              label={email ? "請輸入帳號(Email)" : ""}
              placeholder={!email ? "請輸入帳號(Email)" : ""}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyDown={(e) => {
                handleEnterLogin(e);
              }}
              InputProps={{
                startAdornment: (
                  <MailOutlineIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      color: theme.black.black700,
                    }}
                  />
                ),
              }}
              style={{ marginBottom: 24 }}
            />
            <TextField
              className={classes.textfield_password}
              error={errorTextField === 2 ? true : false}
              helperText={
                errorTextField === 2 ? (
                  <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                    <Box marginTop="4px">{errorMessages}</Box>
                  </Fade>
                ) : null
              }
              fullWidth
              height={56}
              type={visible ? "text" : "password"}
              label={password ? "密碼(8個字元以上)" : ""}
              placeholder={!password ? "密碼(8個字元以上)" : ""}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                handleEnterLogin(e);
              }}
              InputProps={{
                startAdornment: (
                  <LockOutlinedIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      color: theme.black.black700,
                    }}
                  />
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => setVisible(!visible)}
                    disableRipple
                    style={{ width: 24, height: 24 }}
                  >
                    {visible ? (
                      <Visibility
                        style={{
                          width: 24,
                          height: 24,
                          color: theme.black.black700,
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          width: 24,
                          height: 24,
                          color: theme.black.black700,
                        }}
                      />
                    )}
                  </IconButton>
                ),
              }}
              style={{ marginBottom: 24 }}
            />
            <TextField
              className={classes.textfield_password}
              error={errorTextField === 3 ? true : false}
              helperText={
                errorTextField === 3 ? (
                  <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                    <Box marginTop="4px">{errorMessages}</Box>
                  </Fade>
                ) : null
              }
              fullWidth
              height={56}
              type={visible ? "text" : "password"}
              label={passwordConfirm ? "請再次輸入密碼" : ""}
              placeholder={!passwordConfirm ? "請再次輸入密碼" : ""}
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
              onKeyDown={(e) => {
                handleEnterLogin(e);
              }}
              InputProps={{
                startAdornment: (
                  <LockOutlinedIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      color: theme.black.black700,
                    }}
                  />
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => setVisible(!visible)}
                    disableRipple
                    style={{ width: 24, height: 24 }}
                  >
                    {visible ? (
                      <Visibility
                        style={{
                          width: 24,
                          height: 24,
                          color: theme.black.black700,
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{
                          width: 24,
                          height: 24,
                          color: theme.black.black700,
                        }}
                      />
                    )}
                  </IconButton>
                ),
              }}
              style={{ marginBottom: 8 }}
            />
            <Box marginLeft="5px" marginBottom={"8px"}>
              <CheckBox
                checked={agreement}
                onChange={() => {
                  setAgreement(!agreement);
                }}
                label={
                  <Typography variant={matchSM ? "body2" : "caption2"}>
                    完成註冊後，即表示您同意
                    <Link href="/terms" target="_blank">
                      <b>《網站服務條款》</b>
                    </Link>
                  </Typography>
                }
              />
              {errorTextField === 4 ? (
                <Fade in={true} timeout={{ enter: 300, exit: 0 }}>
                  <Typography
                    variant="body2_loose"
                    style={{ color: "#e20f0f" }}
                  >
                    請勾選上方同意條款以繼續註冊帳號
                  </Typography>
                </Fade>
              ) : null}
            </Box>
          </div>
          {/* buttons box */}
          <Box
            style={{
              padding: matchSM ? "0 24px" : "0 16px",
              display: "flex",
              marginBottom: 16,
            }}
          >
            <Button
              variant="secondary"
              width={88}
              onClick={() => {
                setStep(0);
              }}
              style={{ marginRight: 8 }}
            >
              上一步
            </Button>
            <Button
              variant="primary"
              loading={loading}
              fullWidth
              onClick={handleRegister}
            >
              註冊帳號
            </Button>
          </Box>
        </div>
      ) : null}
      {step === 0 ? (
        <div
          style={{
            marginBottom: matchSM ? 24 : 16,
          }}
        >
          <Typography
            variant={matchSM ? "body1" : "caption2"}
            style={{ textAlign: "center" }}
          >
            已經有帳號？
            <Link
              onClick={() => setValue(0)}
              style={{
                cursor: "pointer",
              }}
            >
              <b>點這裡登入</b>
            </Link>
          </Typography>
        </div>
      ) : null}
    </Box>
  );
};

interface ResetPasswordProps {
  onClose: () => void;
  setResetPassword: (resetPassword: boolean) => void;
  setIsOpenSnackbar: (isOpenSnackbar: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
}
const ResetPassword = ({
  onClose,
  setResetPassword,
  setIsOpenSnackbar,
  email,
  setEmail,
}: ResetPasswordProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleRequestResetPassword = async () => {
    try {
      if (email.length === 0) {
        throw "請輸入先前註冊的Email，將寄發重置密碼的信件";
      }
      if (!handleEmailValidation(email)) {
        throw "請確認信箱格式是否正確";
      }
      setLoading(true);

      const requestResetPasswordResult: any = await RequestResetPassword({
        email: email,
        type: "business",
      });
      setLoading(false);
      if (
        requestResetPasswordResult &&
        requestResetPasswordResult.errors === null
      ) {
        onClose();
        setIsOpenSnackbar(true);
        setResetPassword(false);
      } else if (
        requestResetPasswordResult &&
        requestResetPasswordResult.status === "404"
      ) {
        setErrorMessage("此信箱尚未註冊會員");
      }
    } catch (err: any) {
      setErrorMessage(err);
    }
  };
  const handleEnterLogin = (event: any) => {
    if (event.keyCode === 13) {
      handleRequestResetPassword();
    }
  };
  return (
    <Fade in={true}>
      <Box padding="24px">
        <TextField
          error={errorMessage.length !== 0}
          helperText={errorMessage}
          fullWidth
          height={56}
          type="email"
          label="請輸入帳號(註冊時的 Email)"
          autoFocus
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            handleEnterLogin(e);
          }}
          InputProps={{
            startAdornment: (
              <MailOutlineIcon
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  color: theme.black.black700,
                }}
              />
            ),
          }}
          style={{ marginBottom: 16 }}
        />
        <Button
          variant="primary"
          loading={loading}
          fullWidth
          onClick={() => {
            handleRequestResetPassword();
          }}
        >
          重新設定密碼
        </Button>
      </Box>
    </Fade>
  );
};

export default AuthModal;

function TaxIdIcon(props: any) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V11H11V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM9 11H4V9H9V11ZM14 7H4V5H14V7Z"
        fill={props.focused ? "#4B4B4B" : "#6F6F6F"}
      />
    </svg>
  );
}

function CompanyIcon(props: any) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12H14V14H16V12ZM16 8H14V10H16V8ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM8 4H6V2H8V4ZM8 8H6V6H8V8ZM8 12H6V10H8V12ZM8 16H6V14H8V16ZM4 4H2V2H4V4ZM4 8H2V6H4V8ZM4 12H2V10H4V12ZM4 16H2V14H4V16ZM10 4V0H0V18H20V4H10Z"
        fill={props.focused ? "#4B4B4B" : "#6F6F6F"}
      />
    </svg>
  );
}
