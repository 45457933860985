export function validateTaxId(
  taxId: string,
  setCompanyName: Function,
  getCompanyName: Function,
  setHelperText: Function,
  changeFontWeight: Function,
  errorOneText: string,
  errorTwoText: string,
  successText: string
) {
  const cx = new Array();
  cx[0] = 1;
  cx[1] = 2;
  cx[2] = 1;
  cx[3] = 2;
  cx[4] = 1;
  cx[5] = 2;
  cx[6] = 4;
  cx[7] = 1;

  let sum = 0;
  const cnum = taxId.split("");

  function getDoubleDigitSum(number: string) {
    if (Number(number) > 9) {
      const s = number + "";
      const n1: string = s.substring(0, 1);
      const n2: string = s.substring(1, 2);
      number = String(Number(n1) + Number(n2));
    }
    return number;
  }

  if (taxId.length !== 8) {
    setHelperText(errorOneText);
    setCompanyName("");
    changeFontWeight("normal");
  } else {
    for (let i = 0; i < 8; i++) {
      if (taxId.charCodeAt(i) < 48 || taxId.charCodeAt(i) > 57) {
        setHelperText(errorTwoText);
        setCompanyName("");
        changeFontWeight("normal");
        return;
      }
      sum += Number(getDoubleDigitSum(String(Number(cnum[i]) * cx[i])));
    }

    if (sum % 10 == 0) {
      setHelperText(successText);
      getCompanyName(taxId);
    } else if (cnum[6] == "7" && (sum + 1) % 10 == 0) {
      setHelperText(successText);
      getCompanyName(taxId);
    } else {
      setHelperText(errorTwoText);
      setCompanyName("");
      changeFontWeight("normal");
    }
  }
}
